export const environment = "staging"
const ssoScope = [
    "talenta:employee:list",
    "talenta:employee:read",
    "talenta:company:all",
    "talenta:payroll:all",
    "talenta:update-payroll-component:all",
]
export const config = {
    
    api_url: {
        dev: "https://api.incentive-wari-staging.superp.app",
        staging: "https://api.incentive-wari-client.superp.app",
        production: "https://api.mekaripeople.com"
    },
    login_url: {
        dev: "https://sandbox-account.mekari.com/auth?client_id=EnxKKETzD9HeWkzI&response_type=code&scope="+ssoScope.join("%20"),
        staging: "https://account.mekari.com/auth?client_id=ChPg85cyG62sl2HO&response_type=code&scope="+ssoScope.join("%20"),
    },
    logout_url: {
        dev: "https://sandbox-hr.talenta.co/",
        staging:"https://hr.talenta.co/" 
    },
    fe_url: {
        dev: "https://incentive-wari-staging.superp.app",
        staging: "https://incentive-wari-client.superp.app",
        production: "https://mekaripeople.com"
    },
    dateFormat: "D MMM YYYY",
    timeFormat: "HH.mm",
    dateTimeFormat: "DD MMM YYYY, HH:mm",
};
export const baseApi = config.api_url[environment];
export const loginUrl = config.login_url[environment];
export const logoutUrl = config.logout_url[environment];
export const webURL = config.fe_url[environment];

export const validateMessages = {
    required: "${label} is required", // eslint-disable-line
    types: {
        email: "Please input valid email",
    },
};
